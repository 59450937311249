<template>
  <div class="login">
    <div class="login-content">
      <div class="login-phone">
        <div
          class="span"
          @click="showChangeConstoryAction"
        >
          <div>+{{selectCountry[constoryIndex].tel}}</div>
          <!-- <picker :range="selectCountry" @change="changeCountry" range-key="name">
						<div>+{{selectCountry[selectIndex].tel}}</div>
					</picker> -->
        </div>
        <input
          v-model="tel"
          class="input phone"
          type="number"
          :disabled="check == 1"
          :placeholder="check == 1 ? '输入原手机号' : (check == 2 ? '输入手机号' : '请输入手机号码')"
        />
      </div>
      <div class="login-validate">
        <div class="login-phone">
          <input
            v-model="code"
            type="tel"
            maxlength="4"
            class="input validate-code tl"
            placeholder="请输入验证码"
          />
        </div>
        <button
          class="phone-validate"
          @click.stop="getCodeAction"
        >{{countdown ? `${countdown} 秒` : '发送验证码'}}</button>
      </div>
      <div class="login-btn-div">
        <button
          type="primary"
          class="login-btn"
          @click="loginAction"
        >
          <template v-if="check == 1">下一步</template>
          <template v-else-if="check == 2">立即绑定</template>
          <template v-else>登录</template>
        </button>
      </div>
    </div>
    <div
      class="show-change"
      v-if="showChangeConstory"
    >
      <van-picker
        title="选择国家"
        :columns="columns"
        :item-height="70"
        :default-index="constoryIndex"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </div>

    <div
      v-if="!check"
      class="tips flex"
      @click="agreeAction"
    >
      <img
        v-if="agree"
        src="https://artimg2.artart.cn/image/20220429/9642d8bf332eda8c58d5e82cbf1b7f1c.png"
        alt=""
      >
      <img
        v-else
        src="https://artimg2.artart.cn/image/20220429/adb79bc3fef946d7b3dab5e5f837a843.png"
        alt=""
      >
      <div>未注册的手机号将自动创建账号，登录即代表您同意并接受 <span @click.stop="goAgreeAction(4)">用户隐私协议</span> 和 <span @click.stop="goAgreeAction(6)">服务协议</span></div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, computed, watch, onBeforeMount } from "vue";
import { Picker, Toast } from "vant";
import constoryArr from "./Login.json";
import { conutDown, isWeiXin } from "@/utils/util";
import { captchaApi } from "@/api/user";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { getQueryObject } from "@/utils/util";
import { editUserInfo } from "@/api/user";

export default {
  components: {
    [Picker.name]: Picker,
  },
  props: {
    isCheckPhone: {
      type: Number,
      default: 0,
    },
    isLogin: {
      type: Number,
      default: 2,
    },
    isBind: Boolean,
    check: {
      type: String,
      default: "",
    },
  },
  setup(props, content) {
    const { check, isLogin, isBind } = toRefs(props);

    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const tel = ref(""); // 手机号码
    const code = ref(""); // 验证码
    const selectCountry = ref(constoryArr); // 国家列表
    const columns = ref(constoryArr.map((m) => m.name)); // 国家名称列表
    const constoryIndex = ref(0); // 选中的国家下标
    const showChangeConstory = ref(false); // 选择国家开关
    const countdown = ref(0); // 倒计时
    const isWechatBindPhone = ref(Number(isWeiXin()));

    const bgColor = "#000"; // 背景颜色
    const color = "#FFF"; // 文字颜色

    const agree = ref(true);

    onBeforeMount(() => {
      if (check.value === "1") {
        tel.value = store.getters.phone;
      }
    });

    // 打开选择国家界面
    const showChangeConstoryAction = () => {
      showChangeConstory.value = true;
    };

    // 关闭选择国家界面
    const onCancel = () => {
      showChangeConstory.value = false;
    };

    // 确认选择国家
    const onConfirm = (value, index) => {
      constoryIndex.value = index;
      onCancel();
    };

    const timeout = ref(null);

    const resetForm = () => {
      if (timeout.value) clearInterval(timeout.value);
      tel.value = "";
      code.value = "";
      countdown.value = "";
    };

    // 发送验证码
    const getCodeAction = () => {
      const phone = tel.value;
      if (countdown.value) return false;
      if (!phone || isNaN(phone)) {
        Toast("请输入有效手机号");
        return false;
      }

      let area_code = "+86";
      if (constoryIndex.value) {
        area_code = "+" + selectCountry.value[constoryIndex.value].tel;
      }
      conutDown(
        60,
        (hour, minite, second) => {
          countdown.value = second;
        },
        (key) => (timeout.value = key)
      );
      captchaApi({ phone: phone, area_code: area_code })
        .then((res) => {
          console.log(res);
        })
        .catch(({ msg }) => {
          console.log(msg);
        });
    };

    const loginAction = () => {
      const phone = tel.value;
      const sms_code = code.value;
      if (!phone || isNaN(phone)) {
        Toast("请输入有效手机号");
        return false;
      }
      if (!sms_code || isNaN(sms_code)) {
        Toast("请输入验证码");
        return false;
      }
      let area_code = "+86";
      if (constoryIndex.value) {
        area_code = "+" + selectCountry.value[constoryIndex.value].tel;
      }
      if (isBind.value) {
        bindPhoneAction(phone, sms_code, area_code);
        return false;
      }
      if (check.value === "1") {
        checkPhoneAction(phone, sms_code, area_code);
        return false;
      }
      if (check.value === "2") {
        rebindPhoneAction(phone, sms_code, area_code);
        return false;
      }

      if (!agree.value) {
        Toast("清先阅读并同意用户协议");
        return false;
      }
      let invite_uid = "";
      if (route.query.jumpUrl) {
        const jumpQuery = getQueryObject(
          decodeURIComponent(route.query.jumpUrl)
        );
        if (jumpQuery.invite_uid) {
          invite_uid = jumpQuery.invite_uid;
        }
      }
      if (isWechatBindPhone.value) {
        // console.log(getQueryObject(decodeURIComponent(route.query.jumpUrl)));
        // return false;

        if (route.query.bindPhoneToken) {
          store
            .dispatch("user/bindPhone", {
              phone: phone,
              sms_code: sms_code,
              bindPhoneToken: route.query.bindPhoneToken,
              area_code: area_code,
              invite_uid: invite_uid,
            })
            .then(() => {
              router.push(decodeURIComponent(route.query.jumpUrl));
            })
            .catch((message) => Toast(message));
        } else {
          Toast("错误，没有绑定token");
          // console.log("错误，没有绑定token");
        }
        // console.log(route.query);
      } else if (isLogin.value === 2) {
        store
          .dispatch("user/phoneLogin", {
            phone: phone,
            sms_code: sms_code,
            bindPhoneToken: route.query.bindPhoneToken,
            area_code: area_code,
            invite_uid: invite_uid,
            type: 1
          })
          .then(() => {
            router.push(decodeURIComponent(route.query.jumpUrl));
          })
          .catch((message) => Toast(message));
      }
    };

    const checkPhoneAction = (phone, sms_code, area_code) => {
      content.emit("oneOk");
    };

    const rebindPhoneAction = (phone, sms_code, area_code) => {
      content.emit("twoOk");
    };

    const bindPhoneAction = (phone, sms_code, area_code) => {
      editUserInfo({
        type: "setPhone",
        phone_type: "2",
        phone: phone,
        sms_code: sms_code,
        area_code: area_code,
      })
        .then(() => {
          router.back();
        })
        .catch((message) => Toast(message));
    };

    const agreeAction = () => {
      agree.value = !agree.value;
    };

    const goAgreeAction = (type) => {
      router.push("/users/agreement?type=" + type);
    };
    return {
      constoryIndex,
      tel,
      code,
      countdown,
      showChangeConstory,
      selectCountry,
      columns,
      isWechatBindPhone,

      bgColor,
      color,
      agree,

      showChangeConstoryAction,
      onConfirm,
      onCancel,
      getCodeAction,

      loginAction,
      agreeAction,
      goAgreeAction,
      resetForm,
    };
  },
};
</script>

<style scoped lang="scss">
.login {
  .show-change {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2003;
  }

  .login-content {
    z-index: 23;
    width: 590px;
    border-radius: 12px;
    margin-left: 80px;
    padding-top: 352px;

    .login-title {
      text-align: center;
      height: 96px;
      line-height: 96px;
      font-size: 30px;
      position: relative;

      .close-img {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;

        image {
          width: 30px;
          height: 30px;
          display: block;
        }
      }
    }

    .login-validate {
      margin: 36px 30px 0 30px;
      display: flex;
      align-items: center;
      border: 2px solid #333;
      color: #666;
      border-radius: 40px;

      .login-phone {
        margin: 0;
        flex: 1;
        border: none;

        input {
          text-align: center;
          &.tl {
            text-align: left;
            padding-left: 40px;
          }
        }
      }

      .phone-validate {
        width: 164px;
        line-height: 80px;
        text-align: center;
        color: #00eeff;
        background: none;
        border-radius: 10px;
        border: none;
        margin: 0;
        padding: 0;
        font-size: 28px;
        margin-left: 24px;
        margin-right: 20px;
      }
    }

    .login-phone {
      margin: 36px 30px 0 30px;
      height: 80px;
      line-height: 58px;
      font-size: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 40px;
      border: 2px solid #333;
      color: #666;

      .span {
        display: inline-block;
        width: 140px;
        text-align: center;
      }

      .phone {
        flex: 1;
        position: relative;
        z-index: 100;
        padding: 0;
        height: 54px;
        line-height: 54px;
        border: none;
        font-size: 32px;
        float: right;
        display: block;
        background: none;
      }

      .validate-code {
        flex: 1;
        position: relative;
        z-index: 100;
        padding: 0;
        height: 54px;
        line-height: 54px;
        border: none;
        font-size: 32px;
        float: right;
        display: block;
        background: none;
        width: 100%;
      }
    }

    .login-btn-div {
      margin: 36px 30px 0 30px;
      text-align: center;
      display: flex;

      .login-btn {
        flex: 1;
        height: 96px;
        line-height: 96px;
        text-align: center;
        background: #00eeff;
        color: #000;
        width: 100%;
        display: block;
        font-size: 32px;
        border-radius: 48px;
        border: none;
        font-weight: 500;
      }
    }
  }
}

.tips {
  padding: 0 80px;
  margin-top: 30vh;
  font-size: 24px;
  font-weight: 400;
  color: #999999;
  line-height: 34px;

  img {
    width: 32px;
    height: 32px;
    display: block;
    margin-top: 2px;
  }

  div {
    flex: 1;
    margin-left: 10px;

    span {
      color: #96c3df;
    }
  }
}
</style>